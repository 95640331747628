<template>
  <div class="staff-mien">
    <property-title :backgroundTitle="backgroundTitle" :title="title" />
    <div class="staff-content">
      <div class="content-item first-item">
        <div class="word">
          <div class="title">
            {{cultivate.title}}
          </div>
          <div class="en">
            {{cultivate.engname}}
          </div>
          <div class="des">
          {{cultivate.content}}
          </div>
        </div>
        <div class="picture">
          <el-carousel arrow="always">
            <el-carousel-item v-for="(item,index) in cultivate.photo" :key="index">
              <img :src="'http://www.weaiove.com'+item" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="content-item second-item">
        <div class="picture">
          <el-carousel arrow="always">
            <el-carousel-item v-for="(item,index) in demeanour.photo" :key="index">
             <img :src="'http://www.weaiove.com'+item" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="word">
          <div class="title">
            {{demeanour.title}}
          </div>
          <div class="en">
            {{demeanour.engname}}
          </div>
          <div class="des">
         {{demeanour.content}}
         </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PropertyTitle from "../../components/common/PropertyTitle";
import { apiGetStafflist } from "../../api/index";

export default {
  components: {
    PropertyTitle,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      backgroundTitle: "STYLE",
      title: "员工风采",
      cultivate:[],
      demeanour:[]
    };
  },
  created(){
    apiGetStafflist().then(data=>{
      this. cultivate = data.data.list[0]
      this. demeanour = data.data.list[1]
    })
  }
};
</script>
<style lang="less">
.property-title  {
  margin-bottom: 67px;
}
.picture {
  .el-carousel {
    width: 928px;
    height: 545px;
    .el-carousel__container {
      height: 100%;
    }
  }
}
</style>
<style lang="less" scoped>
.staff-mien {
  width: 100%;
  .content-item {
    width: 100%;
    // height: 420px;
    // padding: 46px 120px;
    display: flex;
    // justify-content: center;
    .picture {
      // padding: 20px;
      img{
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
      .el-carousel {
        border-radius: 10px;
        overflow: hidden;
      }
    }
    .word {
      height: 100%;
      // flex: 1;
      padding: 204px 0 0 160px;
      .title {
        color: #000;
        font-size: 35px;
        font-weight: 500;
        font-family: "苹方-简 中黑体";
        src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
      }
      .en {
        font-size: 20px;
        color: #ccc;
        margin-bottom: 26px;
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
      }
      .des {
        font-size: 22px;
        color: #666;
        line-height: 40px;
        width: 530px;
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
      }
    }
  }
  .first-item {
    height: 693px;
    background: url('../../assets/imgs/bgLine.png') no-repeat top left;
    .picture {
      margin-top: 62px;
      margin-left: 162px;
    }
  }
  .second-item {
    background-color: #fff;
    padding: 76px 160px 100px 160px;
  }
  /deep/.el-carousel__arrow--left {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/left.png') no-repeat center/cover;
    i {
      display: none;
    }
  }
  /deep/.el-carousel__arrow--right {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/right.png') no-repeat center/cover;
    i {
      display: none;
    }
  }
}
</style>
